exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-thank-you-js": () => import("./../../../src/pages/en/thank-you.js" /* webpackChunkName: "component---src-pages-en-thank-you-js" */),
  "component---src-pages-gizlilik-tsx": () => import("./../../../src/pages/gizlilik.tsx" /* webpackChunkName: "component---src-pages-gizlilik-tsx" */),
  "component---src-pages-hakkimda-tsx": () => import("./../../../src/pages/hakkimda.tsx" /* webpackChunkName: "component---src-pages-hakkimda-tsx" */),
  "component---src-pages-hizmetler-tsx": () => import("./../../../src/pages/hizmetler.tsx" /* webpackChunkName: "component---src-pages-hizmetler-tsx" */),
  "component---src-pages-iletisim-tsx": () => import("./../../../src/pages/iletisim.tsx" /* webpackChunkName: "component---src-pages-iletisim-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tesekkur-ederim-tsx": () => import("./../../../src/pages/tesekkur-ederim.tsx" /* webpackChunkName: "component---src-pages-tesekkur-ederim-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-en-blog-post-tsx": () => import("./../../../src/templates/EnBlogPost.tsx" /* webpackChunkName: "component---src-templates-en-blog-post-tsx" */)
}

