import React, { createContext, useReducer } from "react";

interface InitialState {
  theme: string;
}

interface Action {
  type: string;
  payload?: any;
}

const initialState: InitialState = {
  theme: "light",
};

const reducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case "TOGGLE_THEME":
      return {
        ...state,
        theme: state.theme === "light" ? "dark" : "light",
      };
    default:
      return state;
  }
};

export const GlobalStateContext = createContext<InitialState | any>(
  initialState,
);
export const GlobalDispatchContext = createContext<
  React.Dispatch<Action> | any
>(() => {});

const GlobalContextProvider: React.FC = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const GlobalContext = createContext({});
export default GlobalContextProvider;
